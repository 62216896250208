import { useCallback, useEffect, useState } from 'react';

import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { themeJsonLight, themeJsonDark } from './theme'

function messageHandler(data){
  try {
    // IOS
    // eslint-disable-next-line no-undef
    webkit.messageHandlers.bridge.postMessage(JSON.stringify(data));
  } catch (error) {
    console.log("webkit is not defined");
  }
  try {
    // ANDROID
    // eslint-disable-next-line no-undef
    Android.submitted(JSON.stringify(data));
  } catch (error) {
    console.log("Android is not defined");
  }
}
const getSurvey = async () =>{
  // eslint-disable-next-line
  const [_, __, sid, ccid] = window.location.pathname.split("/");
  let res = null;
  try {
    res = await fetch(process.env.REACT_APP_GET_MODEL_BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({sid, ccid, })
    })
    res = await res.json()
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

function App() {
  const [surveyModel, setSurveyModel] = useState(new Model())
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [ ColorScheme, setColorScheme] = useState(themeJsonDark);
  const isDarkTheme = useThemeDetector();
  useEffect(()=>{
   if(isDarkTheme){
    setColorScheme(themeJsonDark) 
   }
      else{
        setColorScheme(themeJsonLight) 
      }
 
    getSurvey()
      .then(res => {
        setSurveyModel(new Model(res.data.content))
        setLoading(false)
      })
      .catch(err => {
        setError(err)
        setLoading(false)
      })
  }, [isDarkTheme])

  const alertResults = useCallback((sender) => {
    const results = sender.data;
    saveSurveyResults(process.env.REACT_APP_SAVE_RESPONSE_BASE_URL, results);
  }, []);

  surveyModel?.applyTheme(ColorScheme);
  surveyModel?.onComplete.add(alertResults);

  useEffect(()=>{console.log(error)},[error])

  if(loading) {
    return (
    <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}>
      <img src="/loader.gif" alt="loading" style={{height:"50px", width:"50px"}}/>
    </div>
    )
  }

  if(error) {
    return (
      <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}>
        <h3>Error, could not load survey</h3>
      </div>
      )
  }

  return <Survey model={surveyModel} />;
}

function saveSurveyResults(url, json) {
  // eslint-disable-next-line
  const [_, uid, sid, ccid] = window.location.pathname.split("/");
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({uid, sid, ccid, data: json})
  })
    .then(response => {
      if (response.ok) {
        // Handle success
        messageHandler({msg:"ok"});
      } else {
        // Handle error
        messageHandler({msg:"ko"});
      }
    })
    .catch(error => {
      // Handle error
      messageHandler({msg:error});
    });
}

const useThemeDetector = () => {
  const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());  
  const mqListener = (e => {
      setIsDarkTheme(e.matches);
  });
  
  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addListener(mqListener);
    return () => darkThemeMq.removeListener(mqListener);
  }, []);
  return isDarkTheme;
}
export default App;
